<template>
  <v-app v-if="hasRole(['ROLE_ADMIN','ROLE_TESTER'])">
    <nav-component :drawer="true" title="Dashboard Users">
      <div class="flexRow" style="margin-top: 20px;">
        <v-text-field label="Search" style="width: 200px; margin-right: 5px;" v-model="search" @input="filter()"/>
        <v-select label="Role" style="width: 200px; margin-right: 5px;" v-model="role" @change="filter()"
                  :items="[{text: 'Any', value: 'ANY'}].concat(Object.keys(roles).map(r => {return {text: roles[r], value: r}}))"/>
        <user-component @reload="loadData()"/>
      </div>
    </nav-component>
    <v-main class="pt-2 pt-md-0">
      <alert-component ref="deleteAlert"/>
      <template v-if="results && users">
        <v-card v-for="(user, i) of users" :key="i" class="card">
          <div class="flexRow">
            <v-card-title v-text="user.username"></v-card-title>
            <div class="spacer"/>
            <v-card-actions>
              <i class="material-icons materialIcon">access_time</i>
              <timeago :datetime="user.createdAt"/>
              <user-component :user="user" :affiliates="results.affiliates"/>
              <i class="material-icons materialIcon"
                 @click="$refs.deleteAlert.show('Delete User', 'Are you sure you want to delete this user?',
                [{text: 'Yes', callback: ()=> {deleteUser(user.id)}}, {text: 'No'}])">delete</i>
            </v-card-actions>
          </div>
          <v-card-text>
            <v-card class="card flexRow" style="justify-content: space-between;">
              <div>
                ID
                <h3 v-text="user.id"/>
              </div>
              <div>
                ROLE
                <h3 v-text="roles[user.permissions[0]]"/>
              </div>
            </v-card>
          </v-card-text>
        </v-card>
      </template>
    </v-main>
  </v-app>
</template>

<script>

import utils from '@/mixins/utils';
import UserComponent from '@/components/UserComponent';
import AlertComponent from '@/components/AlertComponent';
import NavComponent from '@/components/NavComponent';

export default {
  components: {UserComponent, AlertComponent, NavComponent},
  async mounted() {
    await this.loadData();
  },
  mixins: [utils],
  methods: {
    async loadData() {
      const user = await this.httpGet('api/user');
      this.results = {users: user.data};
      this.filter();
    },
    filter() {
      this.users = this.results.users;
      if (this.search !== null && this.search.length > 0) {
        this.users = this.users.filter((u) => {
          return u.username.toLowerCase().includes(this.search.toLowerCase()) ||
            u.id.toLowerCase().includes(this.search.toLowerCase());
        })
      }
      if (this.role && this.role !== 'ANY') {
        this.users = this.users.filter(u => u.permissions[0] === this.role)
      }
    },
    async deleteUser(id) {
      await this.httpPost('api/user/delete', {id});
      await this.loadData();
    }
  },
  data() {
    return {
      search: null,
      role: 'ANY',
      results: null,
    }
  },
}
</script>
<style scoped>
.card {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 20px;
  padding: 10px;
}

.materialIcon {
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  padding: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
</style>
