var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('nav-component',{attrs:{"drawer":true,"title":"Customer Reports"}},[_c('div',{staticClass:"flexRow",staticStyle:{"margin-top":"20px"}},[_c('v-text-field',{staticStyle:{"width":"120px","margin-right":"5px"},attrs:{"label":"Filter"},model:{value:(_vm.customerFilter),callback:function ($$v) {_vm.customerFilter=$$v},expression:"customerFilter"}}),_c('v-select',{staticStyle:{"width":"150px","margin-right":"5px"},attrs:{"label":"Customer","items":[{text: 'All', value: 'ALL'}].concat(Object.keys(_vm.customers).
                  map(function (r) {return {text: _vm.customers[r].customerId, value: _vm.customers[r].customerId}}).filter(function (r) {return _vm.customerFilter.length === 0 || r.text.toLowerCase().includes(_vm.customerFilter.toLowerCase())}))},model:{value:(_vm.customer),callback:function ($$v) {_vm.customer=$$v},expression:"customer"}}),_c('v-select',{staticStyle:{"width":"100px","margin-right":"5px"},attrs:{"label":"Time","items":[{text: 'Monthly', value: 'MONTHLY'}, {text: 'Weekly', value: 'WEEKLY'}, {text: 'Daily', value: null}]},model:{value:(_vm.time),callback:function ($$v) {_vm.time=$$v},expression:"time"}}),_c('v-select',{staticStyle:{"width":"140px","margin-right":"5px"},attrs:{"label":_vm.durationRange ? _vm.durationRange[0] + (_vm.durationRange[1] ? ' - ' + _vm.durationRange[1] : '') : 'Duration',"items":[{text: 'Last month', value: 'MONTH'}, {text: '30 days', value: 'DAYS30'}, {text: '7 days', value: 'DAYS7'},
                            {text: 'Today', value: 'TODAY'}, {text: 'Yesterday', value: 'YESTERDAY'}, {text: 'Range', value: 'RANGE'}]},on:{"input":function($event){return _vm.onDurationSelected()},"change":function($event){return _vm.onDurationSelected()}},model:{value:(_vm.duration),callback:function ($$v) {_vm.duration=$$v},expression:"duration"}}),_c('v-btn',{attrs:{"color":"secondary"},domProps:{"textContent":_vm._s('GO')},on:{"click":_vm.go}})],1)]),_c('v-main',{staticClass:"pt-2 pt-md-0"},[_c('v-dialog',{attrs:{"width":"400"},model:{value:(_vm.durationRangePopup),callback:function ($$v) {_vm.durationRangePopup=$$v},expression:"durationRangePopup"}},[_c('v-date-picker',{attrs:{"color":"secondary","range":"","max":_vm.$date(new Date(), 'yyyy-MM-dd')},on:{"change":function($event){return _vm.onDurationChanged()}},model:{value:(_vm.durationRange),callback:function ($$v) {_vm.durationRange=$$v},expression:"durationRange"}})],1),_c('v-dialog',{attrs:{"width":"800"},model:{value:(_vm.detailsPopup),callback:function ($$v) {_vm.detailsPopup=$$v},expression:"detailsPopup"}},[_c('v-card',[_c('v-card-title',{domProps:{"textContent":_vm._s('Details')}}),_c('v-card-text',[(_vm.results)?_c('v-data-table',{attrs:{"items-per-page":50,"footer-props":{
                            'items-per-page-options': [5, 10, 50, 100]},"headers":[
                      { text: 'Date', value: 'id.date' },
                      { text: 'Customer', value: 'customerName' },
                      { text: 'Type', value: 'type' },
                      { text: 'Status', value: 'status' }
                    ],"items":_vm.results.details},scopedSlots:_vm._u([{key:"item.id.date",fn:function(ref){
                    var item = ref.item;
return [_c('span',{domProps:{"textContent":_vm._s(_vm.$date(new Date(item.timestamp), 'yyyy-MM-dd HH:mm'))}})]}}],null,false,1189422385)}):_vm._e()],1)],1)],1),(_vm.results)?_c('v-data-table',{attrs:{"items-per-page":50,"headers":_vm.headers(),"footer-props":{
                            'items-per-page-options': [5, 10, 50, 100]},"items":_vm.results.map(function (result) {
                      return Object.assign({}, result)
                    })},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
                    var item = ref.item;
return [_c('span',{staticStyle:{"cursor":"pointer"},domProps:{"textContent":_vm._s(_vm.$date(new Date(item.date), _vm.format))},on:{"click":function($event){return _vm.details(item)}}})]}}],null,false,923177041)}):_vm._e(),(_vm.results)?_c('v-btn',{staticStyle:{"margin":"10px"},attrs:{"color":"secondary"},domProps:{"textContent":_vm._s('EXPORT')},on:{"click":_vm.csv}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }