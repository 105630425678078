<template>
  <v-app>
    <nav-component :drawer="true" title="Customer Reports">
      <div class="flexRow" style="margin-top: 20px;">
        <v-text-field label="Filter" style="width: 120px; margin-right: 5px;" v-model="customerFilter"/>
        <v-select label="Customer" style="width: 150px; margin-right: 5px;" v-model="customer"
                  :items="[{text: 'All', value: 'ALL'}].concat(Object.keys(customers).
                  map(r => {return {text: customers[r].customerId, value: customers[r].customerId}}).filter(r => {return customerFilter.length === 0 || r.text.toLowerCase().includes(customerFilter.toLowerCase())}))"/>
<!--        <v-select label="Type"-->
<!--                  :items="[ {text: 'All', value: 'ALL'},{text: 'Validate', value: 'VALIDATE'}, {text: 'Approve', value: 'APPROVE'}, {text: 'Consent', value: 'CONSENT'}]"-->
<!--                  style="width: 100px; margin-right: 5px;"-->
<!--                  v-model="time"/>-->
        <v-select label="Time"
                  :items="[{text: 'Monthly', value: 'MONTHLY'}, {text: 'Weekly', value: 'WEEKLY'}, {text: 'Daily', value: null}]"
                  style="width: 100px; margin-right: 5px;"
                  v-model="time"/>
        <v-select :label="durationRange ? durationRange[0] + (durationRange[1] ? ' - ' + durationRange[1] : '') : 'Duration'"
                  :items="[{text: 'Last month', value: 'MONTH'}, {text: '30 days', value: 'DAYS30'}, {text: '7 days', value: 'DAYS7'},
                            {text: 'Today', value: 'TODAY'}, {text: 'Yesterday', value: 'YESTERDAY'}, {text: 'Range', value: 'RANGE'}]"
                  style="width: 140px; margin-right: 5px;"
                  v-model="duration" @input="onDurationSelected()" @change="onDurationSelected()"/>
        <v-btn color="secondary" v-text="'GO'" @click="go"/>
      </div>
    </nav-component>
    <v-main class="pt-2 pt-md-0">
      <v-dialog v-model="durationRangePopup" width="400">
        <v-date-picker color="secondary" v-model="durationRange" range @change="onDurationChanged()" :max="$date(new Date(), 'yyyy-MM-dd')"/>
      </v-dialog>
      <v-dialog v-model="detailsPopup" width="800">
        <v-card>
          <v-card-title v-text="'Details'"/>
          <v-card-text>
            <v-data-table v-if="results"
                          :items-per-page="50"
                          :footer-props="{
                            'items-per-page-options': [5, 10, 50, 100]}"
                          :headers="[
                      { text: 'Date', value: 'id.date' },
                      { text: 'Customer', value: 'customerName' },
                      { text: 'Type', value: 'type' },
                      { text: 'Status', value: 'status' }
                    ]"
                          :items="results.details">
              <template v-slot:item.id.date="{ item }">
                <span v-text="$date(new Date(item.timestamp), 'yyyy-MM-dd HH:mm')"/>
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-data-table v-if="results"
                    :items-per-page="50"
                    :headers="headers()"
                    :footer-props="{
                            'items-per-page-options': [5, 10, 50, 100]}"
                    :items="results.map(result => {
                      return {...result}
                    })">
        <template v-slot:item.date="{ item }">
          <span style="cursor: pointer;" v-text="$date(new Date(item.date), format)"
                @click="details(item)"/>
        </template>
      </v-data-table>
      <v-btn v-if="results" style="margin: 10px;" color="secondary" v-text="'EXPORT'" @click="csv"/>

    </v-main>
  </v-app>
</template>

<script>

import utils from '@/mixins/utils'
import NavComponent from '@/components/NavComponent'
import {saveAs} from 'file-saver'

export default {
  async mounted() {
    this.customers = await this.getCustomers()
    await this.go()
  },
  mixins: [utils],
  components: {
    NavComponent
  },
  data() {
    return {
      customers: {},
      customerFilter: "",
      customer: null,
      time: null,
      duration: null,
      durationRangePopup: false,
      detailsPopup: false,
      durationRange: null,
      results: null,
      format: 'yyyy-MM-dd',
    }
  },
  methods: {
    filter() {

    },
    onDurationSelected() {
      if (this.duration === 'RANGE') {
        this.durationRangePopup = true
      } else {
        this.durationRange = null
      }
    },
    onDurationChanged() {
      if (this.durationRange && this.durationRange.length > 1) {
        this.durationRange.sort()
      }
      this.durationRangePopup = false
    },
    async go() {
      const params = {}
      if (this.customer) params.customer = this.customer
      if (this.time) params.time = this.time
      if (this.duration) params.duration = this.duration
      if (this.durationRange) {
        params.rangeFrom = this.durationRange[0];
        params.rangeTo = this.durationRange[1]
        if (params.rangeFrom > params.rangeTo) {
          const t = params.rangeFrom;
          params.rangeFrom = params.rangeTo;
          params.rangeTo = t;
        }
        params.rangeFrom = params.rangeFrom + 'T00:00:00.000Z';
        params.rangeTo = params.rangeTo + 'T23:59:59.000Z';
      }
      this.results = (await this.httpGet('api/report/sia?' + new URLSearchParams(params))).data;
    },
    async details(item) {
      const fromTime = new Date(item.date)
      let toTime;
      switch (this.time) {
        case 'MONTHLY':
          toTime = new Date(fromTime.getTime())
          toTime.setTime(toTime.setMonth(toTime.getMonth() + 1))
          break
        case 'WEEKLY':
          toTime = new Date(fromTime.getTime() + (7 * 24 * 60 * 60 * 1000))
          break
        case 'HOURLY':
          toTime = new Date(fromTime.getTime() + (60 * 60 * 1000))
          break
        case 'DAILY':
        default:
          toTime = new Date(fromTime.getTime() + (24 * 60 * 60 * 1000))
          break
      }
      const params = {fromTime: fromTime.toISOString(), toTime: toTime.toISOString()};
      if (this.customer) params.customer = this.customer;
      const res = await this.httpGet('api/report/sia/details?' + new URLSearchParams(params));
      this.results.details = res.data;
      this.detailsPopup = true;
    },
    isEmpty(str){
      return str === undefined || str === null || str.trim() === '';
    },
    csv() {
      this.format = this.time === 'HOURLY' ? 'yyyy-MM-dd HH:00' : 'yyyy-MM-dd'
      let blobData = 'Date, Validate, Approve\r\n'
      this.results.forEach(a => {
        blobData += this.$date(new Date(a.date), this.format) + ',' +
            a.validates + ',' +
            a.approves + '\r\n'
      })
      saveAs(new Blob([blobData], {type: "text/csv;charset=utf-8"}), 'data.csv')
    },
    print(a) {
      console.log(a)
    },
    headers() {
      return [
        {text: 'Date', value: 'date'},
        {text: 'Validate', value: 'validates'},
        {text: 'Approve', value: 'approves'}
      ];
    }
  }
}
</script>

<style scoped>
.flexRow {
  display: flex;
  flex-direction: row;
}
</style>
