var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v("Users")]),_c('v-card-subtitle',[_vm._v("Successfully registered unique users")]),(_vm.humanTotals && _vm.humanTotals.users)?_c('v-card-text',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{staticClass:"text-h1"},[_vm._v(_vm._s(_vm.humanTotals.users.human))]),_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.humanTotals.users.symbol))])])]}}],null,false,573862656)},[_c('span',[_vm._v(" "+_vm._s(_vm.humanTotals.users.formatted)+" ")])])],1):_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"text-h1"},[_vm._v("N/A")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v("Transactions")]),_c('v-card-subtitle',[_vm._v("Successfully completed API transactions")]),(_vm.humanTotals && _vm.humanTotals.transactions)?_c('v-card-text',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{staticClass:"text-h1"},[_vm._v(_vm._s(_vm.humanTotals.transactions.human))]),_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.humanTotals.transactions.symbol))])])]}}],null,false,1579098112)},[_c('span',[_vm._v(" "+_vm._s(_vm.humanTotals.transactions.formatted)+" ")])])],1):_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"text-h1"},[_vm._v("N/A")])])],1)],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-card',[_c('v-card-title',[_vm._v("SDKs")]),_c('v-card-subtitle',[_vm._v("All time number of Android SDK installations")]),(_vm.humanTotals && _vm.humanTotals.sdkInstallations)?_c('v-card-text',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('div',_vm._g(_vm._b({},'div',attrs,false),on),[_c('span',{staticClass:"text-h1"},[_vm._v(_vm._s(_vm.humanTotals.sdkInstallations.human))]),_c('span',{staticClass:"text-h3"},[_vm._v(_vm._s(_vm.humanTotals.sdkInstallations.symbol))])])]}}],null,false,2700492224)},[_c('span',[_vm._v(" "+_vm._s(_vm.humanTotals.sdkInstallations.formatted)+" ")])])],1):_c('v-card-text',{staticClass:"text-center"},[_c('span',{staticClass:"text-h1"},[_vm._v("N/A")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }