<template>
  <div>
    <i class="material-icons materialIcon" @click="openDialog()"
       v-text="user === null ? 'add_circle_outline' : 'create'"/>
    <v-dialog v-model="dialog" width="70%">
      <v-card style="display: flex; flex-direction: column;">
        <v-card-title v-text="user === null ? 'New User' : 'Update User'"/>
        <v-card-text style="overflow-y: scroll;">
          <v-form>
            <v-text-field :disabled="user !== null" label="User Name" v-model="state.username" />
            <v-select v-if="state.permissions" label="Role" v-model="state.permissions[0]"
                      :items="Object.keys(roles).map(r => {return {text: roles[r], value: r}})"/>
            <v-switch v-if="user !== null" label="Reset password" v-model="resetPassword"/>
            <v-switch v-if="user !== null && unlockLogin && hasRole(['ROLE_ADMIN'])" label="Unlock login" @change="handleUnlockLogin" :disabled="disableUnlockLogin"/>
            <v-switch v-if="user !== null && unlock2FA && hasRole(['ROLE_ADMIN'])" label="Reset 2FA" @change="handleReset2FA" :disabled="disableReset2FA"/>
            <v-text-field v-if="user == null || resetPassword" label="Password" type="password" v-model="state.password" :rules="[validationRules.password]"/>
            <v-text-field v-if="user == null || resetPassword" label="Retype Password" type="password" v-model="state.retypePassword" :rules="[validationRules.passRetype]"/>
            <v-text-field  label="Phone number" v-model="state.msisdn" />
            <v-btn color="gey" @click="validateMsisdn">Validate</v-btn>
            <v-text-field label="Device Id:" v-model="deviceIdentifier"/>
            <v-text-field label="Sim Id:" v-model="simIdentifier"/>
          </v-form>
        </v-card-text>
        <v-btn class="card" color="secondary" v-text="user === null ? 'ADD' : 'UPDATE'"
               :disabled="!hasRole(['ROLE_ADMIN', 'ROLE_CAMPAIGN_MANAGER'])" @click="submit()" style="margin-left: 35% ; max-width: 30%;"/>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import utils from '@/mixins/utils';

export default {
  name: 'UserComponent',
  props: {
    user: {
      type: Object,
      required: false,
      default: null
    }
  },
  data() {
    return {
      state: {},
      dialog: false,
      resetPassword: false,
      unlockLogin: false,
      disableUnlockLogin: false,
      unlock2FA: false,
      disableReset2FA: false,
      isPasswordValid: false,
      isRetypePasswordValid: false,
      originalPermission: null,
      shouldReload: true,
      deviceIdentifier: null,
      simIdentifier: null,
      validationRules: {
        password: v => (!v || /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[@#$%^&*!()])(?=\S+$).{8,}$/.test(v)) || 'password must be minimum 8 characters, must contain at least one number, at least one capital letter, at least one small letter and at least one special character',
        passRetype: value => (value && value === this.state.password) || 'Passwords are not the same',
      }
    }
  },
  mixins: [utils],
  methods: {
    openDialog() {
      if (this.shouldReload) {
        this.shouldReload= false;
        this.loadData();
      }

      this.dialog = true;
      this.resetPassword = false;
      this.disableUnlockLogin = false;
      this.disableReset2FA = false;
      this.state = this.user === null ? {permissions: ['ROLE_CAMPAIGN_MANAGER']} : {...this.user};
      this.state.password = '';
      this.state.retypePassword = '';
      this.originalPermission = this.state.permissions[0];
    },
    async validateMsisdn() {
      const result = await this.httpPost('api/auth/validateMsisdn', {"msisdn": this.state.msisdn});
      if (result) {
        this.deviceIdentifier = result.data.deviceIdentifier;
        this.simIdentifier = result.data.simIdentifier;
        this.$emit('reload');
      }
    },
    async submit() {
      if (this.user === null || this.resetPassword) {
        if (this.state.password.length === 0) {
          alert('You have to enter the password');
          return;
        }
        if (this.state.retypePassword != this.state.password) {
          alert('Passwords are not the same');
          return;
        }
        if (this.validationRules.password(this.state.password) !== true || this.validationRules.passRetype(this.state.retypePassword) !== true) {
          return;
        }
      }

      if (this.user === null) {
        if (!this.state.username) {
          alert('User name must be filled');
          return;
        }

        try {
           await this.httpPost('api/user/create', this.state)
           this.$emit('reload');
        } catch (error) {
           alert(error.response.statusText);
        }
      } else {
        try {
           if (this.originalPermission !== this.state.permissions[0] || this.state.password.length > 0) {
             await this.httpPost('api/user/update', this.state)
             Object.assign(this.user, this.state);
           }
        } catch (error) {
           alert(error.response.statusText);
        }
      }

      this.dialog = false;
    },
    async handleUnlockLogin(value) {
      if (value) {
        await this.httpGet('api/user/unlock-login?' + new URLSearchParams({username: this.user.username}));
        this.disableUnlockLogin = true;
        this.shouldReload = true;
      }
    },
    async handleReset2FA(value) {
      if (value) {
        await this.httpGet('api/user/reset-2fa?' + new URLSearchParams({username: this.user.username}));
        this.disableReset2FA = true;
        this.shouldReload = true;
      }
    },
    async loadData() {
      if (this.user !== null) {
        const result1 = await this.httpGet('api/user/is-login-locked?' + new URLSearchParams({username: this.user.username}));
        if (result1) {
          this.unlockLogin = result1.data.locked;
        }

        const result2 = await this.httpGet('api/user/is-2fa-enabled?' + new URLSearchParams({username: this.user.username}));
        if (result2) {
          this.unlock2FA = result2.data.enabled;
        }
      }
    }
  }
}
</script>

<style scoped>
.card {
  margin: 20px;
  padding: 10px;
}

.box {
  flex-grow: 1;
  flex-shrink: 1;
  margin: 10px;
  padding: 5px;
}

.materialIcon {
  cursor: pointer;
  vertical-align: middle;
  font-size: 25px;
  padding: 5px;
}

.flexRow {
  display: flex;
  flex-direction: row;
}
</style>
