import HomeComponent from './pages/index.vue'
import LoginComponent from './pages/login.vue'
import Login2Component from './pages/login2.vue'
import Login3Component from './pages/login3.vue'
import RegisterComponent from './pages/register.vue'
import OverviewComponent from './pages/overview/index.vue'
import SiaReportsComponent from './pages/sia-reports/index.vue'
import MNOComponent from './pages/mno/index.vue'
import UsersComponent from './pages/users/index.vue'

export const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeComponent,
    children: [
      {
        path: '',
        name: 'overview',
        component: OverviewComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'sia-reports',
        name: 'sia-reports',
        component: SiaReportsComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'mno',
        name: 'mno',
        component: MNOComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'users',
        name: 'users',
        component: UsersComponent,
        meta: { requiresAuth: true }
      },
      {
        path: 'login2',
        name: 'login2',
        component: Login2Component,
        meta: { mfa: true }
      },
      {
        path: 'login3',
        name: 'login3',
        component: Login3Component,
        meta: { mfa: true }
      },
      {
        path: 'register',
        name: 'register',
        component: RegisterComponent,
        meta: { mfa: true }
      }
    ]
  },
  {
    path: '/login',
    name: 'login',
    component: LoginComponent
  }
]
