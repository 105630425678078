<template>
  <v-app>
    <nav-component :drawer="true" title="Operator Reports">
    </nav-component>
    <v-main class="pt-2 pt-md-0">
      <v-dialog v-model="durationRangePopup" width="400">
        <v-date-picker
            range
            v-model="durationRange"
            color="secondary"
            @change="onDurationChanged()"
            :max="$date(new Date(), 'yyyy-MM-dd')"/>
      </v-dialog>

      <v-container fluid>
        <Totals/>

        <v-row>
          <v-col cols="12">
            <v-toolbar flat color="blue-grey lighten-5">
              <v-toolbar-title>Reports</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-container>
                  <v-row justify="end">
                    <v-col cols="5" sm="4">
                      <v-select v-model="time"
                                :items="[
                              {text: 'Monthly', value: 'MONTHLY'},
                              {text: 'Weekly', value: 'WEEKLY'},
                              {text: 'Daily', value: null}]"
                      />
                    </v-col>

                    <v-col cols="5" sm="4">
                      <v-select v-model="duration"
                                placeholder="Duration"
                                :items="[
                              {text: 'Last month', value: null},
                              {text: '30 days', value: 'DAYS30'},
                              {text: '7 days', value: 'DAYS7'},
                              {text: 'Today', value: 'TODAY'},
                              {text: 'Yesterday', value: 'YESTERDAY'},
                              {text: 'Range', value: 'RANGE'}]"
                                @input="onDurationSelected()"
                                @change="onDurationSelected()"/>
                    </v-col>
                    <v-col cols="2">
                      <v-btn block color="secondary" v-text="'GO'" @click="go"/>
                    </v-col>
                  </v-row>
                </v-container>
              </v-toolbar-items>
            </v-toolbar>

            <v-tabs vertical v-model="tab">
              <v-tab style="justify-content: left;text-transform: none">
                <v-icon left>mdi-account</v-icon>
                Users
              </v-tab>
              <v-tab style="justify-content: left;text-transform: none">
                <v-icon left>mdi-cellphone-cog</v-icon>
                Transactions
              </v-tab>
              <v-tab style="justify-content: left;text-transform: none">
                <v-icon left>mdi-home-analytics</v-icon>
                SDK Installations
              </v-tab>
              <v-tab style="justify-content: left;text-transform: none">
                <v-icon left>mdi-numeric</v-icon>
                MSISDN
              </v-tab>

              <v-tab-item>
                <UsersReport ref="usersReport"/>
              </v-tab-item>
              <v-tab-item>
                <TransactionsReport ref="transactionsReport"/>
              </v-tab-item>
              <v-tab-item>
                <SDKReport ref="sdkReport"/>
              </v-tab-item>
              <v-tab-item>
                <MSISDNReport ref="msisdnReport"/>
              </v-tab-item>

            </v-tabs>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>

import NavComponent from "@/components/NavComponent.vue";
import Totals from "./totals.vue";
import UsersReport from "./UsersReport.vue";
import TransactionsReport from "./TransactionsReport.vue";
import SDKReport from "./SDKReport.vue";
import MSISDNReport from "./MSISDNReport.vue";
import utils from "@/mixins/utils";
import humanize from "@/mixins/humanize";

export default {
  mixins: [utils, humanize],
  components: {
    NavComponent,
    Totals,
    UsersReport,
    TransactionsReport,
    SDKReport,
    MSISDNReport,
  },
  data() {
    return {
      tab: null,
      time: null,
      duration: null,
      durationRange: null,
      durationRangePopup: false,
      results: null,
    }
  },
  methods: {
    onDurationSelected() {
      if (this.duration === 'RANGE') {
        this.durationRangePopup = true
      } else {
        this.durationRange = null
      }
    },
    onDurationChanged() {
      if (this.durationRange && this.durationRange.length > 1) {
        this.durationRange.sort()
      }
      this.durationRangePopup = false
    },
    async go() {
      const params = {}
      if (this.customer) params.customer = this.customer;
      if (this.time) params.time = this.time;
      if (this.duration) params.duration = this.duration;
      if (this.durationRange) {
        params.rangeFrom = this.durationRange[0];
        params.rangeTo = this.durationRange[1];
        if (params.rangeFrom > params.rangeTo) {
          const t = params.rangeFrom;
          params.rangeFrom = params.rangeTo;
          params.rangeTo = t;
        }
        params.rangeFrom = params.rangeFrom + 'T00:00:00.000Z';
        params.rangeTo = params.rangeTo + 'T23:59:59.000Z';
      }

      switch (this.tab) {
        case 0:
          await this.$refs.usersReport.loadData(params);
          break;
        case 1:
          await this.$refs.transactionsReport.loadData(params);
          break;
        case 3:
          this.$refs.msisdnReport.setDateRange(params);
          break;
      }
    }
  }
}

</script>
