<template>
  <v-card flat>
    <v-card-title>Users Report</v-card-title>
    <v-card-subtitle v-if="results">
      Total unique users in period: {{ totalInPeriod }}
    </v-card-subtitle>
    <v-card-text>
      <v-data-table hide-default-footer disable-pagination
                    :loading="loading"
                    loading-text="Loading... Please wait"
                    :headers="[
                      { text: 'Date', value: 'date' },
                      { text: 'Users', value: 'count' },
                    ]"
                    :items="results">
        <template v-slot:item.date="{ item }">
          <span v-text="$date(item.date, 'P')"/>
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import utils from "@/mixins/utils";

export default {
  name: "UsersReport",
  mixins: [utils],
  props: {},
  data() {
    return {
      loading: false,
      results: [],
      totalInPeriod: 0
    }
  },
  methods: {
    async loadData(params) {
      this.loading = true;

      this.results = (await this.httpGet('api/report/mno/users?' + new URLSearchParams(params))).data
          .map(item => ({
            date: new Date(item[0]),
            count: item[1]
          }));

      this.totalInPeriod = this.results.reduce((sum, x) => (sum + x.count), 0);

      this.loading = false;
    }
  }
}
</script>
